export default {
  name: 'StyleMixins',
  mixins: [],
  props: {
    gutterX: {
        type: String,
        default: ""
    },
    gutterY: {
        type: String,
        default: ""
    },
  },
  data() {
    return {}
  },
  computed: {
    listStyle() {
        let listStyles = {};
        if (this.gutterX) {
            listStyles = {...listStyles, ...{
                '--gutter-x': this.gutterX,
                '--margin-gutter-x': `-${this.gutterX}`,
            }}
        }
        if (this.gutterY) {
            listStyles = {...listStyles, ...{
                '--gutter-y': this.gutterY,
                '--margin-gutter-y': `-${this.gutterY}`,
            }}
        }
        return listStyles;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
  },
}
<template>
	<!-- Slider main container -->
	<div
		v-if="dataSource.length"
		class="swiper-carousel__box sw"
		:style="listStyle"
	>
		<swiper
			ref="mySwiper"
			:options="swiperOptions"
			class="sw__row"
			:class="swiperClass"
			@touchEnd="touchEndSwiper"
			@realIndexChange="realIndexChangeSwiper"
			@clickSlide="clickSlideSwiper"
		>
			<swiper-slide
				v-for="(item, index) in dataSource"
				:key="`swiper-carousel-${index}`"
				class="swiper-slide swiper-carousel__slide sw__row--item"
				:class="slideClass"
			>
				<slot :item="item" :index="index"></slot>
			</swiper-slide>
			<div
				v-if="hasNavigator"
				slot="button-prev"
				class="swiper-button-prev swiper-button-white"
			></div>
			<div
				v-if="hasNavigator"
				slot="button-next"
				class="swiper-button-next swiper-button-white"
			></div>
		</swiper>
	</div>
</template>

<script>
/**
 * Import Mixins
 */
import StyleMixins from '@/mixins/StyleMixins'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
	name: 'SwiperCarousel',
	components: {
		Swiper,
		SwiperSlide,
	},
	mixins: [StyleMixins],
	props: {
		swiperClass: {
			type: String,
			default: '',
		},
		slideClass: {
			type: String,
			default: '',
		},
		dataSource: {
			type: Array,
			default: () => [],
		},
		hasNavigator: {
			type: Boolean,
			default: false,
		},
		swiperOptions: {
			type: Object,
			default: () => {
				return {
					slidesPerView: 'auto',
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				}
			},
		},
	},
	data() {
		return {}
	},
	computed: {
		swiper() {
			return this.$refs.mySwiper?.$swiper
		},
	},
	mounted() {},
	beforeDestroy() {
		this.swiper?.detachEvents()
		this.swiper?.destroy()
	},
	methods: {
		touchEndSwiper() {
			this.$emit('touchEndSwiper', this.swiper.realIndex)
		},
		realIndexChangeSwiper() {
			this.$emit('realIndexChangeSwiper', this.swiper.realIndex)
		},
		clickSlideSwiper(index, realIndex) {
			this.$emit('clickSlideSwiper', realIndex)
		},
		slideNext() {
			this.swiper.slideNext(1000, false)
		},
		slidePrev() {
			this.swiper.slidePrev(1000, false)
		},
	},
}
</script>
<style lang="scss" scoped>
.sw {
	&__row {
		margin-right: var(--margin-gutter-x);
		margin-left: var(--margin-gutter-x);
		&--item {
			padding-left: var(--gutter-x);
			padding-right: var(--gutter-x);
			padding-bottom: var(--gutter-y);
			margin-bottom: 1rem;
		}
	}
}
.swiper-button-white {
	font-weight: 800;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banners-wrapper"},[_c('client-only',[_c('b-container',{staticClass:"px-0 mw-100"},[_c('b-row',[_c('b-col',{staticClass:"px-0",class:_vm.banners.length > 1
							? 'col-md-9 col-lg-9'
							: 'col-md-12 col-lg-12',attrs:{"cols":"12"}},[_c('div',{staticClass:"banners banner-section",style:(_vm.listStyle)},[_c('div',{staticClass:"banners__bg"},[_c('VueSlickCarousel',_vm._b({ref:"slider-banner",staticClass:"banner-slide d-flex",on:{"beforeChange":_vm.syncSliders}},'VueSlickCarousel',_vm.bannerSliderSettings,false),_vm._l((_vm.banners),function(banner,index){return _c('div',{key:index,staticClass:"banner-slide--item",on:{"click":function($event){return _vm.handleClickBannerItem()}}},[(
											banner.video &&
											index === _vm.activeBannerIndex
										)?_c('VideoJsPlayer',{key:banner.video,ref:banner.bannerId,refInFor:true,attrs:{"url-sources":[banner.video],"force-auto-play-with-sound":false,"options":_vm.projectSettings.videoJs.live,"is-use-aws-config":false,"is-play-lowest-first":false,"override-events":['canPlay']},on:{"mutedChange":_vm.mutedChangeHandle,"canPlay":function($event){return _vm.setMuteVideo(banner.bannerId)}}},[_c('template',{slot:"overlay-layout"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]})])],2):_c('div',{staticClass:"banners__bg--img-wrapper"},[_c('img',{staticClass:"banners__bg--img",attrs:{"alt":"background","src":_vm.loadImageSrc(banner.image)},on:{"error":_vm.loadImageError,"click":function($event){return _vm.handleClickBannerItem()}}})])],1)}),0)],1),_c('div',{staticClass:"banners__content"},[_c('b-container',[_c('div',{staticClass:"banners__main d-block"},[_c('div',{key:_vm.keyRender,staticClass:"banners__main--actions"},[(_vm.playingVideo)?_c('b-img',{staticClass:"toggle-mute-icon",attrs:{"src":_vm.mutedVideo[
													_vm.banners[
														_vm.activeBannerIndex
													].bannerId
												]
													? _vm.VIDEO_ICONS.mute
															.activeSrc
													: _vm.VIDEO_ICONS.unmute
															.activeSrc,"alt":"toggle-mute"},on:{"click":_vm.toggleMuteVideo}}):_vm._e()],1)])])],1)])]),(_vm.banners.length > 1)?_c('b-col',{staticClass:"px-0",attrs:{"cols":"12","md":"3","lg":"3"}},[_c('VueSlickCarousel',_vm._b({ref:"vertical-slider-banner",staticClass:"\n\t\t\t\t\t\t\tbanner-slide\n\t\t\t\t\t\t\tvertical-banner-slide\n\t\t\t\t\t\t\td-lg-flex d-none d-md-flex\n\t\t\t\t\t\t"},'VueSlickCarousel',_vm.verticalSliderSetting,false),_vm._l((_vm.banners),function(banner,index){return _c('OverviewCard',{key:index,staticClass:"slider-banner--item",class:{
								inactive: _vm.activeBannerIndex !== index,
							},attrs:{"item":_vm.mappingOverviewData(banner),"not-avatar":true,"show-date":false,"show-author":false,"show-description":false,"show-title":false},nativeOn:{"click":function($event){return _vm.changeSlide(index)}}},[(_vm.activeBannerIndex !== index)?_c('template',{slot:"overlay-img"},[_c('div',{staticClass:"overlay-img"})]):_vm._e()],2)}),1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page landing-page"},[_c('client-only',[_c('Header'),(_vm.banners.length)?_c('Skeleton',{attrs:{"height":"56.25vw"}},[_c('b-container',{staticClass:"container-banner",attrs:{"fluid":""}},[_c('Banner',{attrs:{"banners":_vm.banners,"banner-slider-settings":_vm.sliderSettings,"vertical-slider-setting":_vm.verticalSliderSettings}})],1)],1):_vm._e(),_c('b-container',{staticClass:"container-live-events"},_vm._l((_vm.categories),function(category,index){return _c('OverviewList',{directives:[{name:"show",rawName:"v-show",value:(!category.isHide),expression:"!category.isHide"}],attrs:{"posts":category.streams,"title":_vm.renderCarouselTitle(category.carouselItemType, category.localisedTagName),"mapping-overview-data":_vm.mappingCarouselData(category.carouselItemType),"gutter-x":"1.3rem","has-navigator":category.streams.length > 4,"horizontal-navigator":true,"main-class":category.streams.length > 4 ? 'voting-list' : 'voting-short'},on:{"slide-next":function($event){return _vm.slideNext(index)},"slide-prev":function($event){return _vm.slidePrev(index)}},scopedSlots:_vm._u([{key:"list",fn:function(ref){
var posts = ref.posts;
var mappingOverviewData = ref.mappingOverviewData;
return [_c('div',{staticClass:"d-block"},[_c('SwiperCarousel',{ref:("carousel" + index),refInFor:true,attrs:{"data-source":posts,"swiper-options":_vm.swiperOptions(category.streams.length),"gutter-x":"1.3rem","slide-class":"lo__item lp__item col-8 col-xs-8 col-sm-8 col-md-3 ticket-card-item","swiper-class":"lo__row row"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('OverviewCard',{key:index,staticClass:"slider-popular--item",attrs:{"item":mappingOverviewData(item),"not-avatar":true,"show-date":false,"show-author":false,"is-live":category.isLive},nativeOn:{"click":function($event){_vm.goToDetail(
											mappingOverviewData(item),
											category.carouselItemType
										)}}})]}}],null,true)})],1)]}}],null,true)})}),1),_c('div',{ref:"backToTop",staticClass:"back-to-top",class:{
				'show': _vm.showingBackToTopButton
			},on:{"click":_vm.clickBackToTopButton}},[_c('img',{staticClass:"back-to-top-icon",attrs:{"src":_vm.BACK_TO_TOP,"alt":"back-to-top"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
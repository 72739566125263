<template>
	<div
		class="nav-more-btn"
		:class="isNextNav ? 'next-nav-btn' : 'prev-nav-btn'"
	>
		<nuxt-link :to="routeRedirect">
			<div class="navigator-btn">
				<span v-if="!isNextNav" class="navigator-btn__arrow-left">
					<slot name="prev">
						<span v-html="textArrow"></span>
					</slot>
				</span>
				<span v-if="textBtn" class="navigator-btn__text">
					<slot name="text">
						{{ textBtn }}
					</slot>
				</span>
				<span v-if="isNextNav" class="navigator-btn__arrow-right">
					<slot name="next">
						<span v-html="textArrow"></span>
					</slot>
				</span>
			</div>
		</nuxt-link>
	</div>
</template>

<script>
export default {
	name: 'NavigatorButton',
	props: {
		textArrow: {
			type: String,
			default: '&#60;&#60;&#60;',
		},
		textBtn: {
			type: String,
			default: 'Back',
		},
		isNextNav: {
			type: Boolean,
			default: false,
		},
		routeRedirect: {
			type: String,
			default: '/',
		},
	},
	data() {
		return {}
	},
	mounted() {},
	methods: {},
}
</script>

<style lang="scss" scoped>
.nav-more-btn {
	display: flex;
	&.next-nav-btn {
		justify-content: flex-end;
	}
	&.prev-nav-btn {
		justify-content: flex-start;
	}

	.navigator-btn {
		font-size: 1.2rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #606060;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $font-secondary;
		&__arrow-left {
			margin-right: 0.2rem;
		}
		&__arrow-right {
			margin-left: 0.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>

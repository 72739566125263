import { STATUS_RESPONSES } from '@/configs/SDK'
import { STATUS_MESSAGES, SYSTEM_MESSAGES } from '@/messages/System'
import {isEmpty} from "lodash";
import {orderBy} from "lodash/collection";

const UPCOMING_EVENTS_SHOWING = 5
const PAST_STREAMS_SHOWING = 4
const CAROUSEL_ITEM_TYPE = {
	CATEGORY: 0,
	LIVE: 1,
	VOTING: 2,
	CHANNEL: 3
}

export default {
	name: 'EventMixins',
	mixins: [],
	props: {},
	data() {
		return {
			recPosts: [],
			livePosts: [],
			upCommingPosts: [],
			channelPosts: [],
			recordedStreams: [],
			votes: [],
			categories: [],
			isLoadingData: false,
			gutterScroll: 200,
			isFirstLoaded: false,
			CAROUSEL_ITEM_TYPE,
		}
	},
	computed: {
		tonightStreams() {
			// const formatLivePosts = this.livePosts.map((livePost) =>
			// 	this.mappingLiveEventData(livePost)
			// )
			const formatUpcomingPosts = this.upCommingPosts.map((upPost) =>
				this.mappingUpcommingEventData(upPost, true)
			)
			return [...formatUpcomingPosts].slice(0, UPCOMING_EVENTS_SHOWING)
		},
		featureEventChannels() {
			return this.channelPosts.slice(0, 4)
		},
		featureLives() {
			return this.livePosts.slice(0, 4)
		},
		pastStreams() {
			return this.recordedStreams.slice(0, PAST_STREAMS_SHOWING)
		},
		votingItems() {
			return this.votes
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		async fetchEventHomepage() {
			await this.fetchEventChannels()
			this.isLoadingData = true
			const { code, data } =
				await this.$httpResources.repositories.pages.getHome()
			if (code === STATUS_RESPONSES.SUCCESS) {
				// if (!data.liveStreams?.length) {
				// 	// Using teaser videos
				// 	this.livePosts = TEASER_VIDEOS
				// } else if (data.liveStreams.length === 1) {
				// 	// Just use one teaser video
				// 	this.livePosts = [...data.liveStreams, TEASER_VIDEOS[0]]
				// } else {
				// 	this.livePosts = data.liveStreams
				// }
				this.livePosts = data.liveStreams || []
				this.recPosts = data.recordedStreams || []
				this.upCommingPosts = data.upcomingStreams || []
				this.recordedStreams = data.recordedStreams || []
				this.categories = (data.categories || []).map((category) => ({
					...category,
					carouselItemType: CAROUSEL_ITEM_TYPE.CATEGORY,
					localisedTagName: JSON.parse(category.localisedTagName)
				}))

				if (!isEmpty(data.votings)) {
					const votingItem = {
						carouselItemType: CAROUSEL_ITEM_TYPE.VOTING,
						streams: data.votings || [],
						orderIndex: data.votings[0].orderIndex || 0,
						isHide: data.votings[0].isHide ?? false,
					}
					this.categories.push(votingItem)
				}

				if (!isEmpty(data.liveStreams)) {
					const liveItem = {
						carouselItemType: CAROUSEL_ITEM_TYPE.LIVE,
						streams: data.liveStreams || [],
						orderIndex: data.liveStreams[0].orderIndex || 0,
						isHide: data.liveStreams[0].isHide ?? false,
						isLive: true
					}
					this.categories.push(liveItem)
				}

				if (!isEmpty(this.channelPosts)) {
					const channelItem = {
						carouselItemType: CAROUSEL_ITEM_TYPE.CHANNEL,
						streams: this.channelPosts || [],
						orderIndex: this.channelPosts[0].categoryIndex || 0,
						isHide: this.channelPosts[0].isHide ?? false,
					}
					this.categories.push(channelItem)
				}

				this.categories = orderBy(this.categories, ['orderIndex'], ['asc'])
			}
			this.isLoadingData = false
		},
		async fetchVoting() {
			this.isLoadingData = true
			const { code, data } =
				await this.$httpResources.repositories.voting.getVotes()
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.votes = data || []
			}
			this.isLoadingData = false
		},
		async fetchUpcomingEvents(nextId = 0, limit = 10) {
			this.isLoadingData = true
			let isEnd = false
			const { code, data } =
				await this.$httpResources.repositories.event.allUpcoming(
					nextId,
					limit
				)
			if (code === STATUS_RESPONSES.SUCCESS) {
				isEnd = data.isEnd
				if (!data.isEnd) {
					nextId = data.nextId
				}
			}
			this.isLoadingData = false
			return {
				nextId,
				limit,
				isEnd,
				data: data.result || [],
			}
		},
		async fetchRecEvents(nextId = 0, limit = 10) {
			this.isLoadingData = true
			let isEnd = false
			const { code, data } =
				await this.$httpResources.repositories.event.allRecs(
					nextId,
					limit
				)
			if (code === STATUS_RESPONSES.SUCCESS) {
				isEnd = data.isEnd
				if (!data.isEnd) {
					nextId = data.nextId
				}
			}
			this.isLoadingData = false
			return {
				nextId,
				limit,
				isEnd,
				data: data.result || [],
			}
		},
		async fetchEventChannels(nextId = 0, limit = 10) {
			this.isLoadingData = true
			const isEnd = true
			const { code, data } =
				await this.$httpResources.repositories.eventChannel.current()
			if (code === STATUS_RESPONSES.SUCCESS) {
				this.channelPosts = data
			}
			this.isLoadingData = false
			return {
				nextId,
				limit,
				isEnd,
				data: data || [],
			}
		},
		mappingUpcommingEventData(data, usePortrait = false) {
			return {
				thumbnail:
					usePortrait && data.portraitImageUrl
						? data.portraitImageUrl
						: data.imageUrl,
				title: data.title,
				beginDate: data.beginDate,
				endDate: data.endDate,
				dateTime: data.beginDate,
				author: data.publisher,
				description: data.detail || '',
				slug: '',
				id: data.evenId,
				eventId: data.evenId,
				status: data.status,
				hasLiveStream: !!data.hasLiveStream,
			}
		},
		mappingLiveEventData(data) {
			return {
				thumbnail: data.coverImage,
				title: data.title,
				dateTime: data.date,
				author: data.publisher,
				slug: data.slug,
				id: data.streamId,
				eventId: data.eventId,
				isLive: true
			}
		},
		mappingVotingItemData(data) {
			return {
				id: data.id,
				thumbnail: data.image,
				title: data.title,
				description: data.description,
				startTime: data.startTime,
				endTime: data.endTime,
				status: data.status,
				created: data.created,
			}
		},
		mappingRecEventData(data) {
			return {
				thumbnail: data.stream.imageUrl,
				title: data.stream.title,
				beginDate: data.stream.beginStream,
				endDate: data.stream.endStream,
				dateTime: data.stream.beginStream,
				author: {
					displayName: data.user.displayName,
					userImage: data.user.image,
				},
				slug: data.stream.slug,
				id: data.stream.evenId,
				eventId: data.stream.evenId,
			}
		},
		mappingEventChannelData(data) {
			return {
				thumbnail: data.thumbnail,
				image: data.image,
				title: data.title,
				description: data.description,
				channelId: data.channelId,
				id: data.channelId,
			}
		},
		mappingPastStreamData(data) {
			return {
				thumbnail: data.coverImage,
				image: data.coverImage,
				title: data.title,
				id: data.streamId,
				publisher: data.publisher,
				date: data.date,
				streamId: data.streamId,
				eventId: data.eventId,
			}
		},
		goToChannelDetail(item) {
			if (item?.channelId) {
				this.$router.push(`/channels/${item.channelId}`)
			} else {
				this.$notification.e(
					SYSTEM_MESSAGES.DATA_NOT_FOUND,
					STATUS_MESSAGES.ERROR
				)
			}
		},
		goToVotingDetail(item) {
			if (item?.id) {
				this.$router.push(`/voting/${item.id}`)
			} else {
				this.$notification.e(
					SYSTEM_MESSAGES.DATA_NOT_FOUND,
					STATUS_MESSAGES.ERROR
				)
			}
		},
		goToEventDetail(item) {
			if (item?.eventId) {
				this.$router.push(`/events/${item.eventId}`)
			} else {
				this.$notification.e(
					SYSTEM_MESSAGES.DATA_NOT_FOUND,
					STATUS_MESSAGES.ERROR
				)
			}
		},
		goToStreamDetail(item) {
			if (item?.slug) {
				this.$router.push(`/stream/${item.slug}`)
			} else {
				this.$notification.e(
					SYSTEM_MESSAGES.DATA_NOT_FOUND,
					STATUS_MESSAGES.ERROR
				)
			}
		},
		goToPastStreamDetail(item) {
			if (item?.eventId && item?.streamId) {
				this.$router.push(
					`/events/${item.eventId}?streamId=${item.streamId}`
				)
			} else {
				this.$notification.e(
					SYSTEM_MESSAGES.DATA_NOT_FOUND,
					STATUS_MESSAGES.ERROR
				)
			}
		},
		viewStreamTonight(item, currentEventId = 0) {
			if (item.eventId === currentEventId) {
				if (process.browser) {
					window.scrollTo(0, 0)
				}
				return
			}
			if (item?.slug && !item?.eventId) {
				this.$router.push(`/stream/${item.slug}`)
			} else if (item?.eventId) {
				this.$router.push(`/events/${item.eventId}`)
			} else {
				this.$notification.e(
					SYSTEM_MESSAGES.DATA_NOT_FOUND,
					STATUS_MESSAGES.ERROR
				)
			}
		},
	},
	beforeDestroy() {
		this.isLoadingData = false
	},
}

<template>
	<div class="banners-wrapper">
		<client-only>
			<b-container class="px-0 mw-100">
				<b-row>
					<b-col
						cols="12"
						class="px-0"
						:class="
							banners.length > 1
								? 'col-md-9 col-lg-9'
								: 'col-md-12 col-lg-12'
						"
					>
						<div class="banners banner-section" :style="listStyle">
							<div class="banners__bg">
								<VueSlickCarousel
									ref="slider-banner"
									v-bind="bannerSliderSettings"
									class="banner-slide d-flex"
									@beforeChange="syncSliders"
								>
									<div
										v-for="(banner, index) in banners"
										:key="index"
										class="banner-slide--item"
										@click="handleClickBannerItem()"
									>
										<VideoJsPlayer
											v-if="
												banner.video &&
												index === activeBannerIndex
											"
											:ref="banner.bannerId"
											:key="banner.video"
											:url-sources="[banner.video]"
											:force-auto-play-with-sound="false"
											:options="
												projectSettings.videoJs.live
											"
											:is-use-aws-config="false"
											:is-play-lowest-first="false"
											:override-events="['canPlay']"
											@mutedChange="mutedChangeHandle"
											@canPlay="
												setMuteVideo(banner.bannerId)
											"
										>
											<template slot="overlay-layout">
												<div v-show="false" />
											</template>
										</VideoJsPlayer>
										<div
											v-else
											class="banners__bg--img-wrapper"
										>
											<img
												class="banners__bg--img"
												alt="background"
												:src="
													loadImageSrc(banner.image)
												"
												@error="loadImageError"
												@click="handleClickBannerItem()"
											/>
										</div>
									</div>
								</VueSlickCarousel>
							</div>
							<div class="banners__content">
								<b-container>
									<div class="banners__main d-block">
										<div
											:key="keyRender"
											class="banners__main--actions"
										>
											<b-img
												v-if="playingVideo"
												:src="
													mutedVideo[
														banners[
															activeBannerIndex
														].bannerId
													]
														? VIDEO_ICONS.mute
																.activeSrc
														: VIDEO_ICONS.unmute
																.activeSrc
												"
												alt="toggle-mute"
												class="toggle-mute-icon"
												@click="toggleMuteVideo"
											></b-img>
										</div>
									</div>
								</b-container>
							</div>
						</div>
					</b-col>
					<b-col
						v-if="banners.length > 1"
						cols="12"
						md="3"
						lg="3"
						class="px-0"
					>
						<VueSlickCarousel
							ref="vertical-slider-banner"
							v-bind="verticalSliderSetting"
							class="
								banner-slide
								vertical-banner-slide
								d-lg-flex d-none d-md-flex
							"
						>
							<OverviewCard
								v-for="(banner, index) in banners"
								:key="index"
								class="slider-banner--item"
								:class="{
									inactive: activeBannerIndex !== index,
								}"
								:item="mappingOverviewData(banner)"
								:not-avatar="true"
								:show-date="false"
								:show-author="false"
								:show-description="false"
								:show-title="false"
								@click.native="changeSlide(index)"
							>
								<template
									v-if="activeBannerIndex !== index"
									slot="overlay-img"
								>
									<div class="overlay-img"></div>
								</template>
							</OverviewCard>
						</VueSlickCarousel>
					</b-col>
				</b-row>
			</b-container>
		</client-only>
	</div>
</template>

<script>
import OverviewCard from '@/components/cards/OverviewCard.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { VIDEO_ICONS } from '@/configs/Icons'
import { mapGetters } from 'vuex'

const { VideoJsPlayer } = process.client ? require('bi-live-libs') : {}

export default {
	name: 'Banner',
	components: {
		VueSlickCarousel,
		OverviewCard,
		VideoJsPlayer,
	},
	props: {
		bannerSliderSettings: {
			type: Object,
			default: () => {
				return {
					autoplay: true,
					autoplaySpeed: 8000,
					// focusOnSelect: true,
					slidesToShow: 5,
				}
			},
		},
		verticalSliderSetting: {
			type: Object,
			default: () => {
				return {
					autoplay: true,
					autoplaySpeed: 8000,
					// focusOnSelect: true,
					slidesToShow: 5,
				}
			},
		},
		banners: {
			type: Array,
			default: () => {
				return []
			},
		},
		ratioBanner: {
			type: String,
			default: '50%',
		},
	},
	data() {
		return {
			activeBannerIndex: 0,
			syncFromSwiper: false,
			VIDEO_ICONS,
			mutedVideo: {},
			keyRender: Date.now(),
		}
	},
	computed: {
		...mapGetters({
			screen: 'screen',
			projectSettings: 'projectSettings',
		}),
		listStyle() {
			const ratioScreen =
				(this.screen.innerHeight / this.screen.innerWidth) * 100

			return {
				'--aspect-ratio': `${ratioScreen.toFixed(2)}%`,
			}
		},
		playingVideo() {
			return !!this.banners[this.activeBannerIndex].video
		},
	},
	methods: {
		syncSliders(currentPosition, nextPosition) {
			this.$refs['slider-banner'].pause()
			this.activeBannerIndex = nextPosition
			if (this.bannerSliderSettings?.autoplay) {
				this.$refs['slider-banner'].play()
			}
			this.syncFromSwiper = false
			this.$refs['vertical-slider-banner'].goTo(this.activeBannerIndex)
		},
		changeSlide(index) {
			this.activeBannerIndex = index
			this.$refs['slider-banner'].goTo(index)
			this.$refs['vertical-slider-banner'].goTo(index)
		},
		syncSlidersFromSwiper(realIndex) {
			this.syncFromSwiper = true
			this.$refs['slider-banner'].goTo(realIndex)
		},
		changeSlideSwiper(index) {
			this.syncFromSwiper = true
			this.$refs['slider-banner'].goTo(index)
		},
		mappingOverviewData(data) {
			return {
				thumbnail: data.image,
				title: data.title,
				description: data.description,
				id: data.bannerId,
			}
		},
		mutedChangeHandle(val) {
			this.mutedVideo[this.banners[this.activeBannerIndex].bannerId] = val
			this.keyRender = Date.now()
		},
		toggleMuteVideo() {
			this.$refs[
				this.banners[this.activeBannerIndex].bannerId
			].toggleMuteVideo()
		},
		setMuteVideo(bannerId) {
			if (this.mutedVideo[bannerId]) {
				this.$refs[bannerId]?.muteVideo()
			} else {
				this.$refs[bannerId]?.unMuteVideo()
			}
		},
		handleClickBannerItem() {
			if (process.browser) {
				window.location.href = this.banners[this.activeBannerIndex].url
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.banners-wrapper {
	.banners {
		position: relative;
		width: 100%;
		&__bg {
			height: 100%;
			padding-bottom: 0.5rem;
			@include respond(mobile) {
				max-height: 63vw;
			}
			.banner-slide {
				margin-right: -2rem;
				margin-top: 6rem;
				@include respond(mobile) {
					margin-top: 5rem;
					padding-bottom: 0;
				}
			}
			&--img-wrapper {
				position: relative;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				// padding-top: var(--aspect-ratio); /* Aspect Ratio */
			}
			&--img {
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: auto;
				aspect-ratio: 16/9;
				margin-top: 1rem;
				@include respond(mobile) {
					height: 100%;
					object-fit: cover;
					margin-top: 1rem;
				}
			}
		}
		&__content {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			.container {
				display: flex;
				flex-direction: column;
				padding-top: 10.8rem;
				justify-content: center;
			}
		}
		&__main {
			@include respond(mobile) {
				margin-top: 0;
				.toggle-mute-icon {
					margin-left: 3rem !important;
					cursor: pointer;
					width: 3rem;
				}
			}
			&--title {
				font-family: $font-secondary;
				font-size: 5rem;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: #fff;
				text-transform: uppercase;
				word-break: break-word;
				text-overflow: ellipsis;
				overflow: hidden;
				-webkit-box-orient: vertical;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				height: 13rem;
				width: 50%;
			}
			&--description {
				opacity: 0.8;
				font-family: $font-secondary;
				font-size: 2rem;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.6;
				letter-spacing: normal;
				text-align: left;
				color: #fff;
				margin-bottom: 2rem;
				word-break: break-word;
				text-overflow: ellipsis;
				overflow: hidden;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				display: -webkit-box;
				height: 10rem;
				width: 50%;
			}
			&--actions {
				display: flex;
				align-items: center;
				&--watch {
					margin-right: 2rem;
					.btn-watch-stream {
						padding: 1.5rem;
						border-radius: 4px;
						background-color: #c90668;
						font-family: $font-secondary;
						font-size: 2rem;
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 0.8;
						letter-spacing: normal;
						text-align: center;
						color: #fff;
						min-width: 20rem;
					}
				}
				.toggle-mute-icon {
					cursor: pointer;
				}
			}
			@media only screen and (max-width: 1200px) {
				margin-bottom: 3rem;
				&--title {
					font-size: 4rem;
					height: 10rem;
				}
				&--description {
					font-size: 1.8rem;
					height: 9rem;
				}
				&--actions {
					&--watch {
						.btn-watch-stream {
							padding: 1rem;
							font-size: 1.8rem;
						}
					}
				}
			}
			&.mobile {
				margin-bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.banners__main--title {
					font-size: 2.5rem;
					height: unset;
					margin-bottom: 1rem;
					text-align: center;
				}
				.banners__main--description {
					text-align: center;
					font-size: 1.6rem;
					height: unset;
					width: 90%;
				}
				.banners__main--actions {
					&--watch {
						margin-right: 2rem;
						.btn-watch-stream {
							min-width: unset;
							font-style: normal;
							padding: 1rem 2rem;
						}
					}
					.toggle-mute-icon {
						width: 4rem;
					}
				}
			}
		}
	}
	.banner-slide::v-deep {
		margin: 7rem 1rem 0 1rem;
		height: 100%;
		.slick-list {
			width: 100%;
			max-height: 100%;
			margin-left: -1rem !important;
			margin-right: -1rem !important;
			@include respond(mobile) {
				max-height: 63vw;
			}
		}
		&.vertical-banner-slide {
			padding-top: 0;
		}
		.slick-slide {
			padding-left: 1rem;
			padding-right: 1rem;
			cursor: pointer;
			.banner-slide--item {
				width: 100%;
				.player-wrapper .video-js {
					background-color: #151419;
				}
				.video-js .vjs-tech {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					aspect-ratio: 16/9;
					margin-top: 1rem;
				}
			}
		}
		.o-card {
			&__detail {
				&--title {
					color: #fff;
					margin-bottom: 0.4rem;
				}
				&--description,
				&--datetime,
				&--author {
					color: #fff;
				}
			}
			&.inactive {
				.o-card__detail {
					opacity: 0.6;
				}
			}
			&__thumb-wrapper {
				margin-bottom: 0.2rem;
				.overlay-img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.4);
				}
			}
			&__thumb--img {
				border-radius: 0.6rem;
				box-shadow: 0.2rem 0.3rem 0.3rem 0 #000;
			}
		}
		&.vertical-banner-slide {
			.slick-list {
				max-height: 43vw;
				height: 100% !important;
				width: 90%;
				margin-left: 2% !important;
			}
			.slick-arrow {
				bottom: 10rem;
				top: auto;
				z-index: 3;
				&.slick-prev {
					right: 50%;
					left: auto;
					top: 0;
					transform: rotate(90deg);
					&:before {
						font-size: 30px;
					}
					@include respond(tablet) {
						display: block !important;
					}
				}
				&.slick-next {
					right: 50%;
					left: auto;
					transform: rotate(90deg);
					&:before {
						font-size: 30px;
					}
					@include respond(tablet) {
						display: block !important;
					}
				}
			}
		}
		.slick-arrow {
			bottom: 2rem;
			top: auto;
			z-index: 3;
			&.slick-prev {
				right: 115px;
				left: auto;
				opacity: 1;
				@include respond(tablet) {
					display: none !important;
				}
			}
			&.slick-next {
				right: 50px;
				opacity: 1;
				@include respond(tablet) {
					display: none !important;
				}
			}
		}
		.slick-dots {
			bottom: 1rem;
			text-align: right;
			padding-right: 2rem;
			li {
				button:before {
					font-size: 10px;
				}
			}
		}
		@include respond(tablet) {
			.slick-dots {
				bottom: 1rem;
				text-align: center;
			}
		}
	}
	.banners-mobile::v-deep {
		padding: 1.5rem 0;
		.o-card {
			&__detail {
				&--title {
					color: #fff;
					margin-bottom: 0.4rem;
				}
				&--description,
				&--datetime,
				&--author {
					color: #fff;
				}
			}
			&.inactive {
				.o-card__detail {
					opacity: 0.6;
				}
			}
			&__thumb-wrapper {
				margin-bottom: 1rem;
				.overlay-img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.4);
				}
			}
			&__thumb--img {
				border-radius: 0.6rem;
				box-shadow: 0.2rem 0.3rem 0.3rem 0 #000;
			}
		}
	}
}
</style>

<template>
	<div :style="listStyle" class="list-overview lo" :class="mainClass">
		<div v-if="title || showTopMoreUrl" :class="underlineTitle && 'mb-1'" class="lo__top">
			<div v-if="title" class="lo__title">
				<img
					v-if="iconTitle"
					:alt="title"
					:src="iconTitle"
					class="lo__title--icon"
				/>
				<span class="lo__title--text">{{ title }}</span>
			</div>
			<div v-if="hasNavigator && !horizontalNavigator" class="carousel-navigation">
				<button
					class="
											btn
											btn-primary
											btn-animated
											carousel-navigation-btn
											left-button
										"
					type="button"
					@click="slidePrev"
				>
					<img
						:src="LEFT_ARROW_ICON"
						alt="carousel-navigation-btn"
						class="carousel-navigation-btn-icon"
					/>
				</button>
				<button
					class="
											btn
											btn-primary
											btn-animated
											carousel-navigation-btn
											right-button
										"
					type="button"
					@click="slideNext"
				>
					<img
						:src="RIGHT_ARROW_ICON"
						alt="carousel-navigation-btn"
						class="carousel-navigation-btn-icon"
					/>
				</button>
			</div>
			<slot
				v-if="showTopMoreUrl"
				:showMoreText="showMoreText"
				:showTopMoreUrl="showTopMoreUrl"
				name="nav-top"
			>
				<div class="lo__top--nav">
					<NavigatorButton
						:route-redirect="showTopMoreUrl"
						:text-btn="showMoreText"
						class="list-overview-nav"
						is-next-nav
					>
						<template slot="next">
							<i class="arrow right"></i>
						</template>
						<template slot="text">
							<span class="list-overview-nav--text">{{
									showMoreText
								}}</span>
						</template>
					</NavigatorButton>
				</div>
			</slot>
		</div>
		<div
			v-if="underlineTitle"
			class="underline-title"
		></div>
		<div class="lo__list">
			<button
				v-if="hasNavigator && horizontalNavigator"
				class="
											btn
											btn-primary
											btn-animated
											carousel-navigation-btn
											horizontal-navigator
											left-button
										"
				type="button"
				@click="slidePrev"
			>
				<img
					:src="LEFT_ARROW_ICON"
					alt="carousel-navigation-btn"
					class="carousel-navigation-btn-icon"
				/>
			</button>
			<slot
				:mappingOverviewData="mappingOverviewData"
				:posts="posts"
				name="list"
			>
				<div class="lo__row row">
					<div
						v-for="(post, index) in posts"
						:key="index"
						:class="classItem"
						class="lo__item item"
					>
						{{ post }}
					</div>
				</div>
			</slot>
			<button
				v-if="hasNavigator && horizontalNavigator"
				class="
											btn
											btn-primary
											btn-animated
											carousel-navigation-btn
											horizontal-navigator
											right-button
										"
				type="button"
				@click="slideNext"
			>
				<img
					:src="RIGHT_ARROW_ICON"
					alt="carousel-navigation-btn"
					class="carousel-navigation-btn-icon"
				/>
			</button>
		</div>
		<slot
			v-if="showMoreUrl"
			:showMoreText="showMoreText"
			:showTopMoreUrl="showTopMoreUrl"
			name="nav-bottom"
		>
			<div class="lo__nav">
				<NavigatorButton
					:route-redirect="showMoreUrl"
					class="list-overview-nav"
					is-next-nav
					text-btn="Show more"
				>
					<template slot="next">
						<i class="arrow right"></i>
					</template>
					<template slot="text">
						<span class="list-overview-nav--text">{{
								showMoreText
							}}</span>
					</template>
				</NavigatorButton>
			</div>
		</slot>
	</div>
</template>

<script>
/**
 * Import components
 */
import NavigatorButton from '@/components/NavigatorButton'

/**
 * Import Mixins
 */
import StyleMixins from '@/mixins/StyleMixins'
import {DEFAULT_ICON_STATES} from "~/configs/Icons";

export default {
	name: 'OverviewList',
	components: {
		NavigatorButton,
	},
	mixins: [StyleMixins],
	props: {
		posts: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		iconTitle: {
			type: String,
			default: '',
		},
		showMoreUrl: {
			type: String,
			default: '',
		},
		showTopMoreUrl: {
			type: String,
			default: '',
		},
		showMoreText: {
			type: String,
			default() {
				return this.$t('textActions.Show More')
			},
		},
		mappingOverviewData: {
			type: Function,
			default: (data) => {
				return {
					thumbnail: data.coverImage,
					title: data.title,
					dateTime: data.date,
					author: data.publisher,
					slug: data.slug,
					id: data.streamId,
				}
			},
		},
		classItem: {
			type: String,
			default: '',
		},
		underlineTitle: {
			type: Boolean,
			default: false
		},
		hasNavigator: {
			type: Boolean,
			default: false
		},
		horizontalNavigator: {
			type: Boolean,
			default: false
		},
		mainClass: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		LEFT_ARROW_ICON: DEFAULT_ICON_STATES.arrowLeftIcon.activeSrc,
		RIGHT_ARROW_ICON: DEFAULT_ICON_STATES.arrowRightIcon.activeSrc,
	}),
	computed: {},
	methods: {
		slideNext() {
			this.$emit('slide-next')
		},
		slidePrev() {
			this.$emit('slide-prev')
		},
	},
}
</script>

<style lang="scss" scoped>
.lo {
	padding: 3rem 0 0;

	&__top {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 2.5rem;

		&--nav {
			margin-left: auto;

			.list-overview-nav::v-deep {
				a:first-child {
					z-index: 1;
				}
			}
		}
	}

	&__title {
		font-family: $font-primary;
		font-size: 2.5rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #1f1f1f;
		display: flex;
		align-items: center;
		justify-content: center;

		&--icon {
			width: 3rem;
			height: auto;
			margin-right: 1rem;
		}
	}

	.carousel-navigation {
		&-btn {
			background-color: transparent;
			box-shadow: none;
			padding: 0.5rem;

			&.horizontal-navigator {
				width: 3rem;
				height: 4rem;

				img {
					width: 2rem;
				}
			}
		}
	}

	&__list {
		height: 100%;

		.guide-message {
			text-align: center;
			font-family: $font-secondary;
			font-size: 1.4rem;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #606060;
			margin-bottom: 2rem;
		}
	}

	&__row {
		&.row {
			margin-right: var(--margin-gutter-x);
			margin-left: var(--margin-gutter-x);
		}

		.lo__item {
			padding-left: var(--gutter-x);
			padding-right: var(--gutter-x);
			padding-bottom: var(--gutter-y);
			margin-bottom: 1rem;
		}
	}

	&__nav {
		.list-overview-nav {
			a {
				z-index: 1;
			}

			&--text {
				font-family: $font-secondary;
				font-size: 1.6rem;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: #606060;
			}
		}
	}

	.underline-title {
		width: 5rem;
		height: 0.6rem;
		margin: 1rem 0;
		background-color: #fcb200;
		border-radius: 1rem;
	}

	&.voting-list::v-deep {
		.lo__list {
			width: 100%;
			position: relative;

			.d-block {
				width: 100%;
			}

			.horizontal-navigator {
				position: absolute;
				top: 30%;
				z-index: 2;
				background-color: #ffffff60;

				&.left-button {
					left: 2rem;
				}

				&.right-button {
					right: 0;
				}
			}

			.swiper-wrapper {
				width: 94%;
			}
		}
	}

	&.voting-short::v-deep {
		.lo__list {
			.swiper-wrapper {
				width: 94%;
			}
		}
	}
}
</style>

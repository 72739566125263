import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import { SYSTEM_MESSAGES } from '@/messages/System'
export default {
	name: 'MomentMixins',
	computed: {
		...mapGetters({
			projectSettings: 'projectSettings',
		}),
	},
	data() {
		return {}
	},
	created() {},
	methods: {
		/**
		 *
		 * @param timeString
		 * @returns {Date|*}
		 */
		formatUTC(timeString) {
			const timeArray = timeString.split('T')
			if (timeArray.length === 2) {
				const resTime = timeArray[0]
					.split('/')
					.reverse()
					.join('-')
					.concat('T', timeArray[1])
				return new Date(resTime)
			}
			return timeString
		},

		/**
		 *
		 * @param {*} timeString
		 * @param {*} formatTime
		 * @param {*} timezone
		 * @param {*} locale
		 */
		formatDate(
			timeString,
			formatTime = this.projectSettings.dateTime.format,
			timezone = this.projectSettings.dateTime.timezone,
			locale = this.projectSettings.dateTime.locale
		) {
			if (!timeString) {
				return SYSTEM_MESSAGES.INVALID_DATE
			}

			// Format locale
			if (locale) {
				require(`moment/locale/${locale}`)
				moment.locale(locale)
			}

			if (
				moment(timeString, 'DD/MM/YYYYTHH:mm:ss.SSSZ', true).isValid()
			) {
				const timeArray = timeString.split('T')
				if (timeArray.length === 2) {
					timeString = timeArray[0]
						.split('/')
						.reverse()
						.join('-')
						.concat('T', timeArray[1])
				}
			}

			if (timezone) {
				return moment
					.utc(timeString)
					.tz(this.projectSettings.dateTime.timezone)
					.format(formatTime)
			} else {
				return moment(timeString).format(formatTime)
			}
		},

		/**
		 *
		 * @param {*} tz | empty for using local timezone
		 */
		setDefaultTimezone(tz = '') {
			moment.tz.setDefault(tz)
		},

		/**
		 *
		 * @returns {*}
		 */
		getLocalTimezone() {
			return momenttz.tz.guess()
		},

		/**
		 * Check beginDate is greater than endDate
		 * @param {*} beginDate
		 * @param {*} endDate
		 */
		compareDates(beginDate, endDate) {
			const a = moment(this.formatDate(beginDate))
			const b = moment(this.formatDate(endDate))
			return a.diff(b)
		},
	},
}

<template>
	<div class="page landing-page">
		<client-only>
			<Header/>
			<Skeleton v-if="banners.length" height="56.25vw">
				<b-container fluid class="container-banner">
					<Banner
						:banners="banners"
						:banner-slider-settings="sliderSettings"
						:vertical-slider-setting="verticalSliderSettings"
					/>
				</b-container>
			</Skeleton>
			<b-container
				class="container-live-events"
			>
				<OverviewList
					v-for="(category, index) in categories"
					v-show="!category.isHide"
					:posts="category.streams"
					:title="renderCarouselTitle(category.carouselItemType, category.localisedTagName)"
					:mapping-overview-data="mappingCarouselData(category.carouselItemType)"
					gutter-x="1.3rem"
					@slide-next="slideNext(index)"
					@slide-prev="slidePrev(index)"
					:has-navigator="category.streams.length > 4"
					:horizontal-navigator="true"
					:main-class="category.streams.length > 4 ? 'voting-list' : 'voting-short'"
				>
					<template
						slot="list"
						slot-scope="{ posts, mappingOverviewData }"
					>
						<div class="d-block">
							<SwiperCarousel
								:ref="`carousel${index}`"
								:data-source="posts"
								:swiper-options="swiperOptions(category.streams.length)"
								gutter-x="1.3rem"
								slide-class="lo__item lp__item col-8 col-xs-8 col-sm-8 col-md-3 ticket-card-item"
								swiper-class="lo__row row"
							>
								<template
									#default="{ item, index }"
								>
									<OverviewCard
										:key="index"
										class="slider-popular--item"
										:item="mappingOverviewData(item)"
										:not-avatar="true"
										:show-date="false"
										:show-author="false"
										:is-live="category.isLive"
										@click.native="
											goToDetail(
												mappingOverviewData(item),
												category.carouselItemType
											)
										"
									/>
								</template>
							</SwiperCarousel>
						</div>
					</template>
				</OverviewList>
			</b-container>
			<div
				@click="clickBackToTopButton"
				:class="{
					'show': showingBackToTopButton
				}"
				ref="backToTop"
				class="back-to-top"
			>
				<img
					:src="BACK_TO_TOP"
					class="back-to-top-icon"
					alt="back-to-top"
				/>
			</div>
		</client-only>
	</div>
</template>
<script>
import OverviewList from '@/components/list/OverviewList.vue'
import OverviewCard from '@/components/cards/OverviewCard.vue'
import SwiperCarousel from '@/components/carousels/SwiperCarousel.vue'
import Banner from '@/components/home/Banner.vue'
import EventMixins from '@/mixins/EventMixins'
import Header from '@/components/headers/Header.vue'
import {STATUS_RESPONSES, EVENT_STATUS} from '@/configs/SDK'
import {Skeleton} from 'vue-loading-skeleton'
import i18nMixins from "~/mixins/i18nMixins";
import {DEFAULT_ICON_STATES} from "~/configs/Icons";

export default {
	name: 'Home',
	components: {
		SwiperCarousel,
		Header,
		OverviewList,
		OverviewCard,
		Banner,
		Skeleton,
	},
	mixins: [EventMixins, i18nMixins],
	layout: 'home',
	data() {
		return {
			banners: [],
			sliderSettings: {
				autoplay: true,
				autoplaySpeed: 8000,
				slidesToShow: 1,
				dots: true,
				arrows: true,
				infinite: false,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							autoplay: true,
							autoplaySpeed: 8000,
							slidesToShow: 1,
							arrows: false,
						},
					},
				],
			},
			verticalSliderSettings: {
				autoplay: false,
				autoplaySpeed: 8000,
				slidesToShow: 4,
				arrows: true,
				vertical: true,
				infinite: false,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							autoplay: true,
							autoplaySpeed: 8000,
							slidesToShow: 2,
						},
					},
					{
						breakpoint: 576,
						settings: {
							autoplay: true,
							autoplaySpeed: 8000,
							slidesToShow: 1,
						},
					},
				],
			},
			EVENT_STATUS,
			BACK_TO_TOP: DEFAULT_ICON_STATES.backToTop.activeSrc,
			voteCarousel: 'voteCarousel',
			showingBackToTopButton: false,
		}
	},
	async fetch() {
		await this.fetchEventHomepage()
		await this.getCurrentBanners()
		await this.fetchEventChannels()
		await this.fetchVoting()
	},
	computed: {
		selectedLangId() {
			return this.selectedLang ? this.selectedLang.id.toString() : "0"
		},
	},
	created() {},
	mounted() {
		window.addEventListener('scroll', () => {
			this.showingBackToTopButton = window.top.scrollY > 500;
		})
	},
	methods: {
		swiperOptions(length) {
			return {
				slidesPerView: 'auto',
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				loop: length > 4,
			}
		},
		async getCurrentBanners() {
			const {code, data} =
				await this.$httpResources.repositories.event.currentBanners()

			if (code === STATUS_RESPONSES.SUCCESS) {
				this.banners = data
			}
		},
		slideNext(index) {
			try {
				this.$refs['carousel' + index][0].slideNext();
			} catch (e) {
				console.log(e)
			}
		},
		slidePrev(index) {
			try {
				this.$refs['carousel' + index][0].slidePrev();
			} catch (e) {
				console.log(e)
			}
		},
		renderCarouselTitle(carouselItemType, localisedTagName) {
			switch (carouselItemType) {
				case this.CAROUSEL_ITEM_TYPE.CATEGORY: {
					return localisedTagName[this.selectedLang?.id || 0];
				}
				case this.CAROUSEL_ITEM_TYPE.VOTING: {
					return this.$t('pages.home.Vote for your favourites')
				}
				case this.CAROUSEL_ITEM_TYPE.LIVE: {
					return this.$t('pages.home.Live')
				}
				case this.CAROUSEL_ITEM_TYPE.CHANNEL: {
					return this.$t('pages.home.Channels')
				}
				default: {
					return '';
				}
			}
		},
		mappingCarouselData(carouselItemType) {
			switch (carouselItemType) {
				case this.CAROUSEL_ITEM_TYPE.CATEGORY: {
					return this.mappingPastStreamData
				}
				case this.CAROUSEL_ITEM_TYPE.VOTING: {
					return this.mappingVotingItemData
				}
				case this.CAROUSEL_ITEM_TYPE.LIVE: {
					return this.mappingLiveEventData
				}
				case this.CAROUSEL_ITEM_TYPE.CHANNEL: {
					return this.mappingEventChannelData
				}
				default: {
					return () => {
					};
				}
			}
		},
		goToDetail(data, carouselItemType) {
			switch (carouselItemType) {
				case this.CAROUSEL_ITEM_TYPE.CATEGORY: {
					return this.goToEventDetail(data)
				}
				case this.CAROUSEL_ITEM_TYPE.VOTING: {
					return this.goToVotingDetail(data)
				}
				case this.CAROUSEL_ITEM_TYPE.LIVE: {
					return this.goToEventDetail(data)
				}
				case this.CAROUSEL_ITEM_TYPE.CHANNEL: {
					return this.goToChannelDetail(data)
				}
				default: {
					return () => {
					};
				}
			}
		},
		clickBackToTopButton() {
			window.scrollTo({top: 0, behavior: 'smooth'});
		}
	},
}
</script>

<style lang="scss" scoped>
.landing-page::v-deep {
	padding-top: 0;
	background-color: #151419;

	.main-header {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		width: 100%;
		background: #292a2c;

		.header {
			background-color: #292a2c;
		}

		.bg-navbar {
			background-color: #292a2c;
		}

		.navbar-header {
			background-color: transparent !important;
			@media (max-width: 768px) {
				background-color: #292a2c !important;
			}
		}
	}

	.container-banner {
		padding-left: 0;
		padding-right: 0;
	}

	.container-live-events {
		background-color: #151419;
		padding-top: 0;
		padding-bottom: 3rem;
		max-width: 100%;
	}

	.container-tonight-streams {
		background-color: #151419;
		max-width: 100%;

		.coming-soon-events {
			.lo__item:not(:first-child) {
				margin-left: 4.166666667%;
			}
		}
	}

	.lo {
		&__top {
			&--nav {
				.nav-more-btn {
					.navigator-btn {
						color: #ff6d6a;

						.arrow {
							border: solid #ff6d6a;
							border-width: 0 3px 3px 0;
						}
					}
				}
			}
		}

		&__title {
			color: #ff6d6a;
			text-transform: uppercase;
		}

		&__item {
			&.lp__item {
				cursor: pointer;
			}
		}

		.o-card {
			&__detail {
				&--title {
					margin-bottom: 0.4rem;
				}

				&--description,
				&--datetime,
				&--author {
					font-size: 1.1rem;
				}
			}

			&__thumb-wrapper {
				margin-bottom: 1rem;
			}

			&__thumb--img {
				border-radius: 0.6rem;
				box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1);
			}
		}

		&.tonight-streams {
			.lo__top {
				&--nav {
					.nav-more-btn {
						.navigator-btn {
							color: #f0b333;

							.arrow {
								border: solid #f0b333;
								border-width: 0 3px 3px 0;
							}
						}
					}
				}
			}

			.lo__title {
				color: #f0b333;
				text-transform: uppercase;
			}

			.o-card {
				&__detail {
					&--title {
						color: #fff;
					}
				}

				&--description,
				&--datetime,
				&--author {
					color: #fff;
				}
			}
		}
	}

	.back-to-top {
		position: fixed;
		bottom: 10rem;
		right: 5rem;
		padding: 1rem;
		background-color: #ffffff69;
		border-radius: 1rem;
		cursor: pointer;
		z-index: 999;
		opacity: 0;
		visibility: hidden;
		transition: background-color .3s,
		opacity .5s, visibility .5s;

		&.show {
			opacity: 1;
			visibility: visible;
		}
	}
}
</style>

<template>
	<div class="overview-card o-card" :class="thumbnailPosition">
		<div class="o-card__thumb-wrapper">
			<div class="o-card__thumb-inner" :style="{ paddingTop: ratio }">
				<div class="o-card__thumb">
					<img
						:src="loadImageSrc(item.thumbnail)"
						class="o-card__thumb--img"
						alt="thumbnail"
						@error="loadImageError"
					/>
					<slot name="overlay-img"></slot>
				</div>
				<div v-if="isLive" class="o-card__label-status">
					{{ $t('pages.home.Live') }}
				</div>
				<div v-if="isPk" class="o-card__label-status-pk">
					<img
						:src="PK_ICON"
						class="o-card__label-status-pk-img"
						alt="thumbnail"
					/>
				</div>
				<div
					v-if="isPlaying"
					class="o-card__label-status playing-label"
				>
					{{ $t('pages.event.Playing') }}
				</div>
			</div>
		</div>
		<div class="o-card__info">
			<div v-if="!notAvatar" class="o-card__image">
				<img
					:src="loadImageSrc(item.author.userImage)"
					class="o-card__image--avatar"
					alt="thumbnail"
					@error="loadImageError"
				/>
			</div>
			<div class="o-card__detail">
				<slot name="info-detail" :item="item">
					<div v-if="showTitle" class="o-card__detail--title">
						{{ item.title }}
					</div>
					<div
						v-if="showDescription"
						class="o-card__detail--description"
					>
						{{ item.description }}
					</div>
					<div v-if="showDate" class="o-card__detail--datetime">
						{{ formatDate(item.dateTime) }}
					</div>
					<div v-if="showAuthor" class="o-card__detail--author">
						{{ item.author.displayName }}
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>

<script>
import MomentMixins from '@/mixins/MomentMixins'
import {PK_ICONS} from "@/configs/Icons";
export default {
	name: 'OverviewCard',
	mixins: [MomentMixins],
	props: {
		item: {
			type: Object,
			default: () => {
				return {
					thumbnail: '',
					title: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the',
					dateTime: '2020-12-10T08:43:23.0000000Z',
					author: {
						countryCode: '',
						displayName: 'Naruto Uzumaki from Konoha',
						isSeller: 1,
						position: 0,
						totalGoldFans: 0,
						userId: 1292,
						userImage:
							'https://hlive-dev-clients.belive.sg/profile_image/6b3cdbcac97d4d5eb9978ec164e6fe89.jpg?t=637619615890000000',
						userName: '6b3cdbcac97d4d5eb9978ec164e6fe89',
					},
					slug: '2733fbbaa0754572a14a189bc175f04e',
					id: 1163,
				}
			},
		},
		ratio: {
			type: String,
			default: '50%',
		},
		notAvatar: {
			type: Boolean,
			default: false,
		},
		thumbnailPosition: {
			type: String,
			default: 'top',
		},
		isLive: {
			type: Boolean,
			default: false,
		},
		showDate: {
			type: Boolean,
			default: true,
		},
		showAuthor: {
			type: Boolean,
			default: true,
		},
		showDescription: {
			type: Boolean,
			default: false,
		},
		isPlaying: {
			type: Boolean,
			default: false,
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		isPk: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		PK_ICON: PK_ICONS.pkLogo.activeSrc,
	}),
	methods: {},
}
</script>

<style lang="scss" scoped>
.o-card {
	&__thumb-wrapper {
		width: 100%;
		margin-bottom: 1.3rem;
	}

	&__label-status {
		position: absolute;
		top: 0.7rem;
		left: 0.7rem;
		padding: 0.5rem 0.6rem;
		border-radius: 0.2rem;
		background-image: linear-gradient(
			to right,
			#ec038b,
			#fb6468 52%,
			#fbb936
		);
		font-size: 1.2rem;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: center;
		color: #f1f9ff;
		text-transform: uppercase;
		&.playing-label {
			background-image: none;
			background-color: #000000ad;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2rem;
		}
		&-pk {
			position: absolute;
			top: 0.5rem;
			right: 0.7rem;
			&-img {
				width: 2.2rem;
				height: 2.2rem;
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: row;
	}

	&.left {
		flex-direction: row;
		display: flex;
		.o-card__thumb-wrapper {
			margin-right: 1rem;
			width: 45%;
		}
		.o-card__info {
			width: 55%;
		}
	}

	&__thumb-inner {
		width: 100%;
		padding-top: 50%; /* 2:1 Aspect Ratio */
		position: relative; /* If you want text inside of it */
	}
	&__thumb {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		&--img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__image {
		width: 4.8rem;
		height: 4.8rem;
		margin-right: 1rem;
		&--avatar {
			width: 4.8rem;
			height: 4.8rem;
			border-radius: 50%;
		}
	}
	&__detail {
		&--title {
			font-family: $font-primary;
			font-size: 1.6rem;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			margin-bottom: 0.7rem;
			word-break: break-word;
		}
		&--datetime,
		&--description,
		&--author {
			font-family: $font-primary;
			font-size: 1.3rem;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.63;
			letter-spacing: normal;
			text-align: left;
			color: #606060;
			word-break: break-word;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			display: -webkit-box;
		}
	}
}
</style>
